import * as React from 'react'
import { Container, Box, Heading } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import renderAst from '../components/ast-components'
import NewsletterSection from '../components/newsletter-section'
import SocialBanner from '../components/social-banner'

const shopifyPages = ({ data: { page }, pageContext: { breadcrumb } }) => {
  return (
    <Layout breadcrumbs={breadcrumb}>
      <Seo title={page.title} description={page.bodySummary} />
      <Container py={10}>
        <Heading as="h1" mb={2}>
          {page.title}
        </Heading>
        <Box>{renderAst(page.childHtmlRehype.htmlAst)}</Box>
        {(page.handle === 'awards' || page.handle === 'meet-the-team') && (
          <>
            <NewsletterSection mt={6} />
            <SocialBanner mt={6} />
          </>
        )}
      </Container>
    </Layout>
  )
}

export default shopifyPages

export const query = graphql`
  query ($handle: String!) {
    page: shopifyPage(handle: { eq: $handle }) {
      id
      body
      bodySummary
      handle
      title
      childHtmlRehype {
        htmlAst
        html
      }
    }
  }
`
