import React from 'react'
import {
  Box,
  Container,
  Text,
  SimpleGrid,
  Heading,
  useColorModeValue,
  Stack,
  Center,
} from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import Newsletter from './newsletter-serverside'
import Signup from '../icons/signup'

const Banner = ({ ...props }) => {
  const headingColor = useColorModeValue(`headingColor`, `dark.headingColor`)

  return (
    <Box py={10} {...props} style={'clear:both'}>
      <SimpleGrid columns={[1, 1, 1, 2]} spacing={10}>
        <Box>
          <Stack spacing={4}>
            <Heading as="h1" color={headingColor}>
              Join the Mud Club
            </Heading>

            <Text>
              If you’d like to hear about our latest offers and keep in touch
              with news from the pie world, just sign up here for the Mud Club
              newsletter. Easy as pie!
            </Text>
            <Text as="b">
              Get 10% off your first order and a chance to win free pies in our
              monthly draw!
            </Text>
            <Text as="em">
              “I was absolutely surprised, having just signed up for your online
              newsletter, to hear that I had won a dozen pies in your monthly
              draw.  It almost feels like winning the Lottery, but hopefully
              that’s next month!!!”
              <Text textAlign="right" fontSize="sm">
                Ian, Midhurst
              </Text>
            </Text>

            <Newsletter colorScheme="brand.gray" />
            <Center>
              <Box maxW={250}>
                <Signup />
              </Box>
            </Center>
          </Stack>
        </Box>
        <Box p={0} data-name="product-image-box">
          <StaticImage
            src="../images/newsletter/newsletter-image.jpg"
            alt="Pies by email"
            layout="constrained"
            quality={50}
            formats={['auto', 'webp']}
            width={480}
            placeholder="blurred"
          />
        </Box>
      </SimpleGrid>
    </Box>
  )
}

export default Banner
