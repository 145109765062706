import React from 'react'
import {
  Container,
  Flex,
  Icon,
  Link,
  Heading,
  useColorModeValue,
  HStack,
} from '@chakra-ui/react'
import { FaFacebookSquare, FaTwitterSquare } from 'react-icons/fa'
import InstaIcon from '../icons/instagram'

const Banner = ({ ...props }) => {
  const headingColor = useColorModeValue(`headingColor`, `dark.headingColor`)

  return (
    <Container py={10} {...props}>
      <Flex alignItems={'center'} flexDirection="column" mt={6}>
        <Heading as="h3" color={headingColor}>
          Follow us
        </Heading>
        <HStack>
          <Link ml={1} href="https://facebook.com/mudfoods" isExternal>
            <Icon
              w={28}
              h={28}
              display="block"
              aria-label="Find us on facebook"
              as={FaFacebookSquare}
              color="#1877f2"
            />
          </Link>

          <Link ml={2} href="https://instagram.com/mudfoods" isExternal>
            <InstaIcon
              w={24}
              h={24}
              display="block"
              aria-label="Find us on Insagram"
            />
          </Link>
          <Link ml={1} href="https://twitter.com/mudfoods" isExternal>
            <Icon
              w={28}
              h={28}
              display="block"
              aria-label="Find us on twitter"
              as={FaTwitterSquare}
              color="#1d9bf0"
            />
          </Link>
        </HStack>
      </Flex>
    </Container>
  )
}

export default Banner
